import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleAnalyticService } from "../../../core/services/google-analytic.service";

@Component({
    selector: 'app-dbd-blog-dialog',
    templateUrl: './dbd-blog-dialog.component.html',
    styleUrls: ['./dbd-blog-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DbdBlogDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DbdBlogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { blogUrl: string; tripName: string },
    private googleAnalyticService: GoogleAnalyticService
  ) {}

  ngOnInit() {
    this.googleAnalyticService.setDayByDayAnalytic();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
