<div class="dialog">
  <button class="dialog__close-btn" (click)="closeModal()"></button>
  <div class="dialog__header">
    <h2>{{data.tripName}} - day by day</h2>
  </div>
  <div class="dialog__content">
    @if (data && data.blogUrl) {
      <div class="dbd-iframe-box">
<!--        <iframe [attr.src]="data.blogUrl | safeResourceUrl"></iframe>-->
      </div>
    }
  </div>
</div>
